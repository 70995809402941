import React, {useEffect} from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import styles from "../styles/blog.module.scss"
import RWindow  from "../components/rwindow"
import NavBar from "../components/navbar"
import {Socials} from "../components/socials"
import Footer  from "../components/footer"

import {toggleReload} from "../state/app"
import {connect} from "react-redux"

function mapStateToProps(state, ownProps) {
  return {
    props: ownProps
  }
}

const shortcodes = { Link } // Provide common components here
const PageTemplate =  ({ data: { allMdx, mdx, site }, dispatch}) => {

  useEffect(() => {
    console.log("click")
    dispatch(toggleReload(true));
  })

  console.log(mdx);

  return (
      <div>
        <Helmet>

          <meta charSet="utf-8" />

          <title>{mdx.frontmatter.title}</title>
          <link rel="shortcut icon" type="image/png" href="/assets/images/icon.png" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="author" content="Kristen Kwong"/>
          <meta charset="utf-8"/>

          <meta itemprop="description" name="description" content={mdx.frontmatter.summary} />
          <meta name="description" content={mdx.frontmatter.summary} />

          {/* OPEN GRAPH TAGS */}
          <meta property="og:locale" content="en_US"/>
          <meta property="og:type" content="article"/>
          <meta property="og:title" content={mdx.frontmatter.title}/>
          <meta property="og:image" content={"https://kristen.dev" + mdx.frontmatter.thumbnail} />
          <meta property="og:site_name" content="Kristen Kwong ★ Software Engineer"/>
          <meta property="article:author" content="Kristen Kwong" />
          <meta property="article:published_time" content={mdx.frontmatter.date} />
          <meta property="og:description" content={mdx.frontmatter.summary}/>

          {/* <!--- TWITTER CARD ----> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@kristenkwng" />

          {/*<!-- Author tag that LinkedIn likes -->*/}
          <meta name="author" content="Kristen Kwong"></meta>

        </Helmet>

      <NavBar />
      <div className="safe-area">
        <div className="safe-area-container">
          <RWindow name="blog-post" display={true} title={mdx.frontmatter.title} parentClass={styles.postWindow}>
          <div className={styles.date}>{mdx.frontmatter.date}</div>
          <div className={styles.tags}>
            Categories: {mdx.frontmatter.tags.join(", ")} 
          </div>
          <div className={styles.wordCount}>Word count: {mdx.wordCount.words}</div>
          <span className={styles.blogBody}>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
            <i>Thanks for reading! If you enjoyed this, feel free to connect with me via</i>
            <Socials />
          </span>
          </RWindow>
        </div>
      </div>

      <Footer />
      
    </div>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {

    mdx(id: {eq: $id}) {
      id
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        summary
        thumbnail
        tags
      }
      wordCount {
        words
      }
    }

    allMdx(limit: 5, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          body
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            summary
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default connect(mapStateToProps, null)(PageTemplate);